//@author: devin

import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { NgZone } from "@angular/core";
import { ServiceLoader } from "../../services/service.loader";
import { S25Util } from "../../util/s25-util";
import { LangService } from "../../services/lang.service";
import { ModalMap, ModalName } from "./modal.map";
import { jSith } from "../../util/jquery-replacement";

export interface ModalData {
    lang?: any;
    isUnsupportedBrowser?: boolean;
    closeModal?(): void;
    callBack?(): void;
    modalInstance?: NgbModalRef;
    title?: string;
    iconClass?: string;
}

export class ModalService {
    private static zone: NgZone = undefined;
    private static ngbModal: NgbModal = undefined;
    private static returnToFocusElement: any = null;
    private static queue: Promise<unknown>[] = [];
    private static openModals: Promise<void>[] = [];

    public static modal(modalType: ModalName, data?: any) {
        ModalService.zone = ModalService.zone || ServiceLoader.get(NgZone);
        ModalService.ngbModal = ModalService.ngbModal || ServiceLoader.get(NgbModal);
        ModalService.returnToFocusElement = document.activeElement;
        const { promise, resolve, reject } = S25Util.createPromise<void>();
        this.openModals.push(promise);
        let opened = false;

        return ModalService.zone.run(() => {
            return LangService.getLang().then((lang) => {
                let component: any = typeof modalType === "string" ? ModalMap.map[modalType].controller : modalType;
                let modalRef: NgbModalRef = ModalService.ngbModal.open(component, {
                    animation: !S25Util.isInIframe,
                    keyboard: true,
                    size: ModalMap.map[modalType].size || "lg",
                    backdrop: modalType === "dialog" || (ModalMap.map[modalType].asDialog ? "static" : true),
                    windowClass: modalType || "generic-modal",
                });
                opened = true;

                if (modalRef.componentInstance) {
                    data = data || {};
                    if (modalType === "dialog") {
                        data.answer = 0;
                    }
                    if (ModalMap.map[modalType].defaultData)
                        data =
                            (ModalMap.map[modalType].defaultData &&
                                S25Util.coalesceDeep(data, ModalMap.map[modalType].defaultData)) ||
                            data;
                    data.title ??= ModalMap.map[modalType].title;
                    data.iconClass ??= ModalMap.map[modalType].iconClass;
                    data.lang = lang; //inject lang into all modals but dont include in merge above bc that would be expensive
                    data.isUnsupportedBrowser = S25Util.isUnsupportedBrowser();
                    data.closeModal = function (result?: any) {
                        return modalRef.close(result);
                    };
                    data.modalInstance = modalRef;
                    modalRef.componentInstance.data = data;
                }

                // ngb modal automatically focuses the first focusable element in the modal
                // but this causes a problem because, in an iframe, it takes a bit for the modal to
                // position itself, so if the modal is opened and focused immediately, the focus will
                // cause the page to scroll to the top. So we override and eliminate the autofocus behavior
                // by placing the ngbAutofocus attribute on the modal header, which is not focusable.
                // Then we wait 1 second for the modal to be positioned properly and focus the first
                // focusable element in the modal ourselves
                S25Util.isInIframe &&
                    setTimeout(() => {
                        let focusableElem = opened && jSith.focusable("ngb-modal-window", 0, false, false);
                        focusableElem && focusableElem.focus();
                    }, 1000);

                modalRef.result.finally(() => {
                    opened = false;
                    ModalService.returnToFocusElement && ModalService.returnToFocusElement.focus();
                    resolve();
                    this.openModals = this.openModals.filter((p) => p !== promise);
                });

                return modalRef.result;
            });
        });
    }

    public static async queueModal<T>(modalType: ModalName, data?: T) {
        await Promise.all(this.openModals); // Wait for any open modal not in queue
        const q = this.queue.slice(); // Copy queue
        const { promise, resolve, reject } = S25Util.createPromise();
        this.queue.push(promise);
        await Promise.all(q); // Wait for other modals
        const ret = await this.modal(modalType, data);
        this.queue.shift(); // Pop own promise
        resolve();
        return ret;
    }

    public static dialogType = function (type: string, data: any, primary?: string) {
        data = data || {};
        let options = type.split(" ");
        data.buttonMap = {};
        jSith.forEach(options, function (_, val) {
            switch (val) {
                case "Yes":
                    data.buttonMap.hasYes = 1;
                    break;
                case "No":
                    data.buttonMap.hasNo = 1;
                    break;
                case "Cancel":
                    data.buttonMap.hasCancel = 1;
                    break;
                case "Continue":
                    data.buttonMap.hasContinue = 1;
                    break;
                case "OK":
                    data.buttonMap.hasOK = 1;
                    break;
                default:
                    break;
            }
        });
        data.buttonMap.primary = primary;
        return data;
    };
}
