//@author: devin
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { ModalInterface } from "./modal.interface";
import { ModalData } from "./modal.service";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { HelpTopic } from "../s25-help/s25.help.service";
import { S25Util } from "../../util/s25-util";

export interface ModalHeaderI extends ModalData {
    helpLink?: HelpTopic;
}

@TypeManagerDecorator("s25-modal-header")
@Component({
    selector: "s25-modal-header",
    template: `
        <div class="modal-header" [attr.ngbAutofocus]="isInIframe ? true : null">
            @if (data.title != " " || data.iconClass) {
                <h4 class="modal-title">
                    @if (data.iconClass) {
                        <span class="{{ data.iconClass }}"></span>
                    }
                    @if (data.title) {
                        <span>{{ data.title }}</span>
                    }
                </h4>
            }
            <span class="empty-title" ng-if="title == '&nbsp;'"></span>
            <div class="modal-header-flex">
                @if (data.helpLink) {
                    <s25-help-link
                        class="ngInlineBlock ngFloatRight ngCpointer ngModalHelp c-modalHelp"
                        [helpTopic]="data.helpLink"
                    >
                        <svg class="c-svgIcon" role="img">
                            <title>Help</title>
                            <use
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#help"
                            ></use>
                        </svg>
                    </s25-help-link>
                }
                <a
                    href="javascript:void(0);"
                    class="s25modal-close s25modal-close-pos"
                    (click)="data.closeModal()"
                    aria-label="Close"
                >
                    <svg class="c-svgIcon" role="img">
                        <title>Close</title>
                        <use xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#close-x"></use>
                    </svg>
                </a>
            </div>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalHeaderComponent implements OnInit, ModalInterface {
    @Input() data: ModalHeaderI;
    isInIframe = S25Util.isInIframe;

    ngOnInit() {}
}
